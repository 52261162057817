import { makeAutoObservable, runInAction } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class PayablesStore {
  paginatedPayables = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetchPaginatedPayables(page = 1, pageSize = 10, sortBy = '-invoiceDate', filter = {}, search = '', searchField = 'invoiceNumber') {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/payables?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      runInAction(() => {
        this.paginatedPayables = {
          ...pagination, results: results.map(payable => ({
            ...payable,
            attachments: payable.attachments || []
          }))
        };
      })
      return { results, ...pagination };
    } catch (error) {
      if (/No documents found/.test(error.response?.data?.message)) {
        runInAction(() => {
          this.paginatedPayables = {
            hasMore: false,
            totalCount: 0,
            currentPage: 0,
            pageSize: 10,
            totalPages: 0,
            results: []
          };
        });
      } else {
        console.error("Error fetching paginated payables:", error);
        throw error
      }
    }
  }

  async fetchPayableById(id) {
    try {
      const response = await apiHandler.get(`/payables/${id}`);
      this.payable = response;
      return response;
    } catch (error) {
      console.error(`Error fetching payable with ID ${id}:`, error);
    }
  }

  async createPayable(newPayableData) {
    try {
      const response = await apiHandler.post('/payables', newPayableData);
      this.paginatedPayables.results.push(response);
    } catch (error) {
      console.error("Error creating new payable:", error);
    }
  }

  async updatePayable(id, updatedPayableData) {
    try {
      const response = await apiHandler.put(`/payables/${id}`, updatedPayableData);
      const index = this.paginatedPayables.results.findIndex(payable => payable._id === id);
      if (index > -1) {
        this.paginatedPayables.results[index] = response;
      }
    } catch (error) {
      console.error(`Error updating payable with ID ${id}:`, error);
    }
  }

  async deletePayable(id) {
    try {
      await apiHandler.delete(`/payables/${id}`);
      this.paginatedPayables.results = this.paginatedPayables.results.filter(payable => payable._id !== id);
    } catch (error) {
      console.error(`Error deleting payable with ID ${id}:`, error);
    }
  }

  async fetchAccountCodes() {
    try {
      const response = await apiHandler.get(`/accountCodes/`);
      return response?.results;
    } catch (error) {
      console.error(`Error fetching accountCodes: `, error);
    }
  } 
}

export default new PayablesStore();
