import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Snackbar, Alert, List, ListItemButton, ListItemText, Button, Divider, Modal, Paper, IconButton, useTheme } from '@mui/material';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import DynamicSearchFilter from '../../components/DynamicSearchFilter';
import InvoiceStore from '../../stores/InvoiceStore';
import ReceivablesStore from '../../stores/ReceivablesStore';
import InvoiceForm from './InvoiceForm';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import { format } from 'date-fns';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import InvoicePDF from '../../pdf/InvoicePDF';
import authStore from '../../stores/AuthStore';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PackingListPDF from '../../pdf/PackingListPDF';

const AddReceivable = () => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [shopOrders, setShopOrders] = useState(null);
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState({
    salesOrderNumber: '',
    invoiceNumber: '',
    invoiceDate: new Date(),
    customer: '',
    customerName: '',
    bundles: 0,
    pieces: 0,
    feet: 0,
    weight: 0,
    additionalCharges: 0,
    totalAmount: 0,
    extraCharges: []
  });
  const [viewPdf, setViewPdf] = useState(false);
  const [viewPackingList, setViewPackingList] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { orderId } = location?.state || {};

  useEffect(() => {
    if (invoiceId && invoiceId !== 'new' && !selectedOrder) {
      fetchInvoice(invoiceId);
      return;
    }
    if (invoiceId === 'new' && !selectedOrder) {
      generateNewInvoiceNumber().then((invoiceNumber) => {
        setInvoice((prevInvoice) => ({ ...prevInvoice, invoiceNumber }));
      });
    }
    if (selectedOrder) {
      console.log(selectedOrder);
      const invoiceFilter = { order: selectedOrder._id };
      fetchOrderInvoices(1, 10, '-invoiceDate', invoiceFilter);
      generateNewInvoiceNumber().then((invoiceNumber) => {
        setInvoice((prevInvoice) => ({ ...prevInvoice, invoiceNumber }));
      });
    }
  }, [selectedOrder, invoiceId]);

  useEffect(() => {
    const fetchOrder = async (orderId) => {
      const fetchedOrder = await ShopOrdersStore.fetchShopOrderById(orderId);
      handleOrderSelect(fetchedOrder);
    };

    if (orderId) {
      fetchOrder(orderId);
    }
  }, [orderId]);

  const fetchOrderInvoices = async (page, pageSize, sortBy, filter, search, searchField) => {
    setLoading(true);
    try {
      const { results } = await InvoiceStore.fetchPaginatedInvoices(page, pageSize, sortBy, filter, search, searchField);
      setInvoices(results);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to fetch invoices', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchInvoice = async (invoiceId) => {
    const filter = { _id: invoiceId };
    const paginatedInvoice = await InvoiceStore.fetchPaginatedInvoices(1, 1, '-invoiceDate', filter);
    const invoice = paginatedInvoice.results[0];
    if (invoice && invoice.order) {
      const order = await ShopOrdersStore.fetchShopOrderById(invoice.order);
      handleOrderSelect(order);
    } else {
      setSnackbar({ open: true, message: 'Invoice not found', severity: 'error' });
    }
  };

  const goBack = () => {
    navigate('/receivables');
  };

  const generateNewInvoiceNumber = async () => {
    try {
      const { results } = await InvoiceStore.fetchPaginatedInvoices(1, 30, '-invoiceDate,-invoiceNumber');
      const lastInvoice = results[0];
      const lastInvoiceNumber = lastInvoice.invoiceNumber;
      const invoiceNumber = +lastInvoiceNumber;
      return invoiceNumber + 1;
    } catch (error) {
      console.error('Error generating new invoice number:', error);
    }
  };

  const handleSearch = (search) => {
    if (search.length > 0) {
      setSelectedOrder(null);

      fetchPaginatedShopOrders(1, 5, '-enteredDate', {}, search, 'salesOrderNumber');
    }
  };

  const fetchPaginatedShopOrders = async (page = 1, pageSize = 5, sortBy = '-enteredDate', filter = {}, search = '', searchField = '') => {
    setLoading(true);
    ShopOrdersStore.fetchPaginatedShopOrders(page, pageSize, sortBy, filter, search, searchField)
      .then(() => {
        setShopOrders(ShopOrdersStore.paginatedShopOrders);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.message === 'No documents found.') {
          setShopOrders({ hasMore: false, totalCount: 0, currentPage: 0, pageSize: 5, totalPages: 0, results: [] });
          setSnackbar({ open: true, message: 'No orders found', severity: 'info' });
        } else {
          setSnackbar({ open: true, message: 'Failed to fetch shop orders', severity: 'error' });
        }
        setLoading(false);
      });
  };

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
    setInvoice({
      ...invoice,
      salesOrderNumber: order.salesOrderNumber,
      customerName: order.customerName,
      bundles: order.bundleCount,
      pieces: order.totalPieces?.actual || 0,
      feet: order.totalFeet?.actual || 0,
      weight: order.weight,
      additionalCharges: order.charges,
      totalAmount: order.total,
      customerPONumber: order.customerPONumber,
      millNo: order.millJobNumber,
      billingAddress: order.billingAddress,
      shippingAddress: order.shippingAddress,
      outerHone: order.tubeOuterDiameter.toFixed(3) || 'N/A',
      innerHone: order.honeInnerDiameter.upperLimit.toFixed(3) || 'N/A',
      orderComments: order.comments,
      costPerFoot: order.costPerFoot.toFixed(2) || 'N/A',
      oal: order.OAL,
      customer: order.customer,
      order: order._id,
      extraCharges: [],
      invoiceCode: '',
      additionalChargeDescription: '',
      additionalComments: ''
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const updateInvoice = (updatedInvoice) => {
    setInvoice(updatedInvoice);
  };

  const handleAddInvoice = async () => {
    const newInvoice = { ...invoice, createdBy: authStore.userId };
    try {
      await InvoiceStore.createInvoice(newInvoice);
      setSnackbar({ open: true, message: 'Invoice added successfully', severity: 'success' });
      const invoiceFilter = { order: selectedOrder._id };
      fetchOrderInvoices(1, 10, '-invoiceDate', invoiceFilter);
      generateNewInvoiceNumber().then((invoiceNumber) => {
        setInvoice({ ...invoice, invoiceNumber });
      });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to add invoice', severity: 'error' });
    }
  };

  const printPdf = async (invoiceId) => {

    try {
      const invoice = await InvoiceStore.fetchInvoiceById(invoiceId);

      const pdfData = {
        ...invoice,
        salesOrderNumber: selectedOrder.salesOrderNumber,
        customerName: selectedOrder.customerName,
        bundles: selectedOrder.bundleCount,
        pieces: selectedOrder.totalPieces?.actual || 0,
        feet: selectedOrder.totalFeet?.actual || 0,
        weight: selectedOrder.weight,
        additionalCharges: selectedOrder.charges,
        totalAmount: selectedOrder.total,
        customerPONumber: selectedOrder.customerPONumber,
        millNo: selectedOrder.millJobNumber,
        billingAddress: selectedOrder.billingAddress,
        shippingAddress: selectedOrder.shippingAddress,
        outerHone: selectedOrder.tubeOuterDiameter.toFixed(3) || 'N/A',
        innerHone: selectedOrder.honeInnerDiameter.upperLimit.toFixed(3) || 'N/A',
        orderComments: selectedOrder.comments,
        costPerFoot: selectedOrder.costPerFoot.toFixed(2) || 'N/A',
        oal: selectedOrder.OAL,
      };

      setPdfData(pdfData);
      setViewPdf(true);
    } catch (error) {
      setSnackbar({ open: true, message: error, severity: 'error' });
    }

  };

  const printPackingList = async (invoiceId) => {
    try {
      const invoice = await InvoiceStore.fetchInvoiceById(invoiceId);

      const pdfData = {
        ...invoice,
        salesOrderNumber: selectedOrder.salesOrderNumber,
        customerName: selectedOrder.customerName,
        bundles: selectedOrder.bundleCount,
        pieces: selectedOrder.totalPieces?.actual || 0,
        feet: selectedOrder.totalFeet?.actual || 0,
        weight: selectedOrder.weight,
        additionalCharges: selectedOrder.charges,
        totalAmount: selectedOrder.total,
        customerPONumber: selectedOrder.customerPONumber,
        millNo: selectedOrder.millJobNumber,
        billingAddress: selectedOrder.billingAddress,
        shippingAddress: selectedOrder.shippingAddress,
        outerHone: selectedOrder.tubeOuterDiameter.toFixed(3) || 'N/A',
        innerHone: selectedOrder.honeInnerDiameter.upperLimit.toFixed(3) || 'N/A',
        orderComments: selectedOrder.comments,
        costPerFoot: selectedOrder.costPerFoot.toFixed(2) || 'N/A',
        oal: selectedOrder.OAL,
      };

      setPdfData(pdfData);
      setViewPackingList(true);
    } catch (error) {
      setSnackbar({ open: true, message: error, severity: 'error' });
    }

  };

  const confirmDelete = async () => {
    if (invoiceToDelete) {
      try {
        await InvoiceStore.deleteInvoice(invoiceToDelete);
        setSnackbar({ open: true, message: 'Invoice deleted successfully', severity: 'success' });
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to delete invoice', severity: 'error' });
      }
      const invoiceFilter = { order: selectedOrder._id };
      fetchOrderInvoices(1, 10, '-invoiceDate', invoiceFilter);
      generateNewInvoiceNumber().then((invoiceNumber) => {
        setInvoice({ ...invoice, invoiceNumber });
      });
      setDeleteModalOpen(false);
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setInvoiceToDelete(null);
  };

  const handleDeleteClick = (invoice) => {
    setInvoiceToDelete(invoice);
    setDeleteModalOpen(true);
  };

  const handleClose = () => {
    setViewPdf(false);
    setPdfData(null);
  };

  const handleClosePackingList = () => {
    setViewPackingList(false);
    setPdfData(null);
  }

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button
        onClick={() => {
          if (selectedRows.length === 1) {
            printPdf(selectedRows[0]);
          }
        }}
        startIcon={<PaymentsIcon />}
        disabled={selectedRows.length !== 1}
        sx={{ ml: 2 }}
      >
        Invoice
      </Button>
      <Button
        onClick={() => {
          if (selectedRows.length === 1) {
            printPackingList(selectedRows[0]);
          }
        }}
        startIcon={<ReceiptIcon />}
        disabled={selectedRows.length !== 1}
      >
        Packing List
      </Button>
      <Button
        onClick={() => {
          if (selectedRows.length === 1) {
            handleDeleteClick(selectedRows[0]);
          }
        }}
        startIcon={<DeleteIcon />}
        disabled={selectedRows.length === 0}
      >
        Delete
      </Button>
    </GridToolbarContainer>
  );

  const columns = [
    { field: 'invoiceNumber', sortable: false, headerName: 'Invoice', flex: 1, type: 'string' },
    { field: 'invoiceDate', sortable: false, headerName: 'Date', flex: 1, valueFormatter: (params) => format(new Date(params.value), 'MM/dd/yyyy') },
    { field: 'bundles', sortable: false, headerName: 'Bundles', flex: 1, type: 'number' },
    { field: 'pieces', sortable: false, headerName: 'Pieces', flex: 1, type: 'number' },
    { field: 'feet', sortable: false, headerName: 'Total Feet', flex: 1, type: 'number' },
    { field: 'weight', sortable: false, headerName: 'Weight', flex: 1, type: 'number' },
    {
      field: 'additionalCharges',
      headerName: 'Charges',
      sortable: false,
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const { additionalCharges, extraCharges, totalAmount } = params.row;
        let calculatedTotal = typeof totalAmount === 'string'
          ? parseFloat(totalAmount.replace(/[$,%]/g, '')) || 0
          : totalAmount;
        extraCharges.forEach((charge) => {
          if (charge.type === "Flat") {
            calculatedTotal += charge.amount;
          } else if (charge.type === "Percentage") {
            calculatedTotal += (calculatedTotal * (charge.amount / 100));
          }
        });
        return `$${(additionalCharges + (calculatedTotal - totalAmount)).toFixed(2)}`;
      }
    },
    {
      field: 'totalAmount',
      headerName: 'Total',
      sortable: false,
      flex: 1,
      type: 'number',
      valueGetter: (params) => {
        const { totalAmount, extraCharges } = params.row;
        let calculatedTotal = typeof totalAmount === 'string'
          ? parseFloat(totalAmount.replace(/[$,%]/g, '')) || 0
          : totalAmount;
        extraCharges.forEach((charge) => {
          if (charge.type === "Flat") {
            calculatedTotal += charge.amount;
          } else if (charge.type === "Percentage") {
            calculatedTotal += (calculatedTotal * (charge.amount / 100));
          }
        });
        return `$${calculatedTotal.toFixed(2)}`;
      }
    },
  ];

  return (
    <Box sx={{ m: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ m: 2 }}>{invoiceId === 'new' ? 'Add New Invoice' : 'Edit Invoices'}</Typography>
        <IconButton onClick={goBack}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        {invoiceId === 'new' && (
          <Box m={2}>
            <DynamicSearchFilter
              defaultSort="-enteredDate"
              label="Search Orders"
              onSearch={handleSearch}
              objectInterface={{ /* Define your order interface here */ }}
            />
          </Box>

        )}
        {loading ? (
          <CircularProgress />
        ) : (
          <Box m={2}>
            <Box>
              {invoiceId === 'new' && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button variant="outlined" color="primary" onClick={() => setSelectedOrder(null)}>Change Order</Button>
                </Box>
              )}
              {!selectedOrder && (
                <Box>
                  <List>
                    {shopOrders?.results.map((order) => (
                      <Box key={order._id}>
                        <ListItemButton onClick={() => handleOrderSelect(order)}>
                          <ListItemText
                            primary={`Order: ${order.salesOrderNumber}`}
                            secondary={`Customer: ${order.customer.companyName || 'N/A'} | Mill No: ${order.millJobNumber}`}
                            secondaryTypographyProps={{ variant: 'body2', margin: '-2px' }}
                          />
                        </ListItemButton>
                        <Divider sx={{ width: '100%' }} />
                      </Box>
                    ))}
                  </List>
                </Box>
              )}
              <InvoiceForm invoice={invoice} onChange={updateInvoice} onSubmit={handleAddInvoice} />
              <Typography variant="h5" sx={{ m: 2 }}>Existing Invoices</Typography>
              {invoices && invoices.length > 0 ? (
                <DataGridPro
                  columns={columns}
                  rows={invoices}
                  getRowId={(row) => row._id}
                  loading={loading}
                  autoHeight
                  checkboxSelection
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnMenu
                  disableMultipleColumnsSorting
                  onRowSelectionModelChange={(selection) => {
                    setSelectedRows(selection);
                  }}
                  selectionModel={selectedRows}
                  slots={{
                    toolbar: CustomToolbar
                  }}
                />
              ) : (
                <Typography textAlign='center'>No invoices exist for this order.</Typography>
              )}
            </Box>

          </Box>
        )}
      </Box>
      {viewPdf && (
        <Modal
          open={viewPdf}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
            >
              <CloseIcon />
            </IconButton>
            <PDFViewer width="100%" height="100%">
              <InvoicePDF invoice={pdfData} />
            </PDFViewer>
          </Paper>
        </Modal>
      )}
      {viewPackingList && (
        <Modal
          open={viewPackingList}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
            <IconButton
              onClick={handleClosePackingList}
              sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
            >
              <CloseIcon />
            </IconButton>
            <PDFViewer width="100%" height="100%">
              <PackingListPDF invoice={pdfData} />
            </PDFViewer>
          </Paper>
        </Modal>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Modal
        open={deleteModalOpen}
        onClose={cancelDelete}
        aria-labelledby="delete-confirmation"
        aria-describedby="delete-confirmation-description"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Paper sx={{ padding: 3, textAlign: 'center' }}>
          <Typography id="delete-confirmation" variant="h6" component="h2">
            Are you sure you want to delete this invoice?
          </Typography>
          <Box mt={2}>
            <Button variant="contained" color="error" onClick={confirmDelete} sx={{ marginRight: 2 }}>
              Yes, Delete
            </Button>
            <Button variant="outlined" onClick={cancelDelete}>
              Cancel
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

export default AddReceivable;
