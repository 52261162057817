import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class CommissionCodeStore {
  commissionCodes = [];
  paginatedCommissionCodes = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  }
  commissionCode = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchPaginatedCommissionCodes(
    page = 1,
    pageSize = 10,
    sortBy = 'commissionCode',
    filter = {},
    search = '',
    searchField = 'commissionCode'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/commissionCodes?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      this.paginatedCommissionCodes = { ...pagination, results: results };
      return { results, ...pagination };
    } catch (error) {
      console.error("Error fetching paginated commission codes:", error);
    }
  }

  async fetchCommissionCodeById(commissionCodeId) {
    try {
      const response = await apiHandler.get(`/commissionCodes/${commissionCodeId}`);
      this.commissionCode = await response;
      return response;
    } catch (error) {
      console.error("Error fetching commission code by ID:", error);
    }
  }

  async createCommissionCode(commissionCodeData) {
    try {
      const newCommissionCode = await apiHandler.post('/commissionCodes', commissionCodeData);
      this.commissionCodes.push(newCommissionCode);
    } catch (error) {
      console.error("Error creating commission code:", error);
    }
  }

  async updateCommissionCode(commissionCodeId, commissionCodeData) {
    try {
      const updatedCommissionCode = await apiHandler.put(`/commissionCodes/${commissionCodeId}`, commissionCodeData);
      const index = this.commissionCodes.findIndex(commissionCode => commissionCode._id === commissionCodeId);
      if (index > -1) {
        this.commissionCodes[index] = updatedCommissionCode;
      }
    } catch (error) {
      console.error("Error updating commission code:", error);
    }
  }

  async deleteCommissionCode(commissionCodeId) {
    try {
      await apiHandler.delete(`/commissionCodes/${commissionCodeId}`);
      this.commissionCodes = this.commissionCodes.filter(commissionCode => commissionCode._id !== commissionCodeId);
    } catch (error) {
      console.error("Error deleting commission code:", error);
    }
  }
}

export default new CommissionCodeStore();