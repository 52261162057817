import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PayablesStore from '../../stores/PayablesStore';
import {
  Box, Typography, CircularProgress, Paper, Divider, Grid, IconButton, Tooltip, Snackbar, Alert, Container, Stack, Avatar, Card, CardContent, Modal
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { PDFViewer } from '@react-pdf/renderer';
import CheckPDF from '../../pdf/CheckPDF';
import { formatCurrency } from '../../utils/numberFormatting';

const PayableDetails = () => {
  const [payable, setPayable] = useState(null);
  const [otherPayables, setOtherPayables] = useState([]);
  const [loading, setLoading] = useState(false);
  const { payableId } = useParams();
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [checkData, setCheckData] = useState({});

  useEffect(() => {
    setLoading(true);
    PayablesStore.fetchPayableById(payableId)
      .then((fetchedPayable) => {
        setPayable(fetchedPayable);
        setLoading(false);
  
        if (fetchedPayable.paidAmount > fetchedPayable.invoiceAmount) {
          const searchFilter = {
            paidAmount: {
              $gt: 262.39 - 0.001,
              $lt: 262.39 + 0.001
            },
            checkNo: fetchedPayable.checkNo,
          };
          PayablesStore.fetchPaginatedPayables(1, 20, '', searchFilter, '', '').then((response) => {
            const filteredPayables = response.results.filter(payable => payable._id !== payableId);
            setOtherPayables(filteredPayables);
          });
        }
      })
      .catch(() => setLoading(false));
  }, [payableId]);

  const handleBack = () => navigate(-1);

  const handleEdit = () => navigate(`/payable/edit/${payableId}`);

  const handleDelete = () => {
    PayablesStore.deletePayable(payable.id)
      .then(() => navigate('/payables'))
      .catch(() => setSnackbar({ open: true, message: 'Error deleting payable', severity: 'error' }));
  };

  const handlePrintPayable = () => {
    const data = {
      checkNumber: payable.checkNo,
      date: new Date().toLocaleDateString(),
      payee: payable.vendor.vendorName,
      amount: payable.invoiceAmount,
      text: `Invoice No: ${payable.invoiceNo}, Amount: $${payable.invoiceAmount.toFixed(2)}`
    };
    setCheckData(data);
    setViewPdf(true);
  };

  const handleCardClick = (id) => {
    navigate(`/payable/${id}`);
  }

  const formatDate = (date) =>
    date ? format(new Date(date), 'MM/dd/yyyy') : 'N/A';

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton component={Link} to="/payables">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 2 }}>Payable Details</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Tooltip title="Edit Payable">
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Print Payable">
              <IconButton onClick={handlePrintPayable}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Payable">
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar sx={{ width: 56, height: 56 }}>{payable?.vendor?.vendorName[0]}</Avatar>
                  <Box>
                    <Typography variant="h6">{payable?.vendor?.vendorName || 'Vendor Name Not Available'}</Typography>
                    {payable?.vendor?.email && <Typography variant="body2" color="textSecondary">{payable.vendor.email}</Typography>}
                    {payable?.vendor?.phone && <Typography variant="body2" color="textSecondary">{payable.vendor.phone}</Typography>}
                  </Box>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" color="textSecondary">Vendor ID: {payable?.vendor?.vendorId || 'N/A'}</Typography>
                <Typography variant="body2" color="textSecondary">Check Number: {payable?.checkNo || 'N/A'}</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Invoice Information</Typography>
                <Typography variant="body2" color="textSecondary">
                  Invoice Number: {payable?.invoiceNo || 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Invoice Amount: {payable?.invoiceAmount ? `$${payable.invoiceAmount.toFixed(2)}` : 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Paid Amount: {payable?.paidAmount !== undefined ? `$${payable.paidAmount.toFixed(2)}` : 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Due Date: {formatDate(payable?.dueDate)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Invoice Date: {formatDate(payable?.invoiceDate)}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" color="textSecondary"><strong>Status:</strong> {payable?.payThis ? 'To be paid' : 'Not paid'}</Typography>
                <Typography variant="body2" color="textSecondary"><strong>Void:</strong> {payable?.void ? 'Yes' : 'No'}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>

      {otherPayables.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Other Payables on this Check Number</Typography>
          <Grid container spacing={2}>
            {otherPayables.map((otherPayable) => (
              <Grid item xs={12} key={otherPayable.id}>
                <Card onClick={() => handleCardClick(otherPayable._id)} style={{ cursor: 'pointer' }}>
                  <CardContent>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {otherPayable.vendor?.vendorName || "Vendor not available"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Invoice No: {otherPayable.invoiceNo || "N/A"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Due Date: {otherPayable.dueDate ? formatDate(otherPayable.dueDate) : "N/A"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Total Amount: {otherPayable.invoiceAmount ? formatCurrency(otherPayable.invoiceAmount) : "N/A"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Unpaid Amount: {otherPayable.invoiceAmount ? formatCurrency(Math.max(0, otherPayable.invoiceAmount - (otherPayable.paidAmount || 0))) : "N/A"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Paid Amount: {otherPayable.paidAmount !== undefined ? `$${otherPayable.paidAmount.toFixed(2)}` : 'N/A'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      
      {viewPdf && (
        <Modal
          open={viewPdf}
          onClose={() => setViewPdf(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}
        >
          <Box sx={{ width: '90%', height: '90%', padding: 1 }}>
            <IconButton onClick={() => setViewPdf(false)} sx={{ position: 'absolute', right: 16, top: 16, color: 'white' }}>
              <CloseIcon />
            </IconButton>
            <PDFViewer width="100%" height="100%">
              <CheckPDF report={checkData} />
            </PDFViewer>
          </Box>
        </Modal>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ open: false })}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Container>
  );
};

export default PayableDetails;
