import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CommissionCodeStore from '../../stores/CommissionCodeStore';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Grid,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

const CommissionCodeDetails = () => {
  const { commissionCodeId } = useParams();
  const [commissionCode, setCommissionCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommissionCode = async () => {
      try {
        const fetchedCommissionCode = await CommissionCodeStore.fetchCommissionCodeById(commissionCodeId);
        setCommissionCode(fetchedCommissionCode);
      } catch (error) {
        setSnackbar({ open: true, message: 'Error fetching commission code', severity: 'error' });
        console.error('Error fetching commission code:', error);
      } finally {
        setLoading(false);
      }
    };

    if (commissionCodeId) {
      fetchCommissionCode();
    }
  }, [commissionCodeId]);

  const handleBack = () => {
    navigate('/commission-codes');
  };

  const handleEdit = () => {
    navigate(`/commission-codes/edit/${commissionCodeId}`);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ m: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">Commission Code Details</Typography>
        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      </Box>
      {commissionCode ? (
        <Box>
          <ListItem>
            <ListItemText primary="Commission Code" secondary={commissionCode.commissionCode} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Description" secondary={commissionCode.commissionDescription} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Comments" secondary={commissionCode.comments} />
          </ListItem>
        </Box>
      ) : (
        <Typography variant="subtitle1">No commission code found</Typography>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CommissionCodeDetails;