import React from 'react';
import { Box, TextField, Grid, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';

const CommissionCodeForm = ({ commissionCode, onSubmit, onChange, back }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ ...commissionCode, [name]: value });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>Commission Code Information</Typography>
          <TextField fullWidth name="commissionCode" label="Commission Code" value={commissionCode.commissionCode} onChange={handleChange} margin="normal" />
          <TextField fullWidth name="commissionDescription" label="Description" value={commissionCode.commissionDescription} onChange={handleChange} margin="normal" />
          <TextField fullWidth name="comments" label="Comments" value={commissionCode.comments} onChange={handleChange} margin="normal" />
        </Grid>
      </Grid>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        <Button variant="outlined" onClick={back}>Back</Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
      </Box>
    </Box>
  );
};

export default CommissionCodeForm;