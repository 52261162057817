import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Typography, Box, Button, Snackbar, Modal, IconButton, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs'; 
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import SalesReceivablesPDF from '../../../pdf/SalesReceivablesPDF';
import DateRangePicker from '../../../components/DateRangePicker';
import { formatCurrency, formatDate } from '../../../utils/numberFormatting';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InvoiceDetailPanel = ({ row }) => {
  const { invoices } = row;

  return (
    <Box sx={{ padding: 2 }}>
      {invoices.map((invoice) => (
        <Box key={invoice._id} sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1">
            Invoice #: {invoice.invoiceNumber}
          </Typography>
          <Typography variant="body2">
            Date: {formatDate(invoice.invoiceDate)} | Total: {formatCurrency(invoice.totalAmount?.$numberDecimal)} | 
            Paid: {formatCurrency(invoice.amountPaid?.$numberDecimal)} | Remaining: {formatCurrency(invoice.remainingBalance)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const ReceivablesReport = observer(() => {
  const [receivablesReport, setReceivablesReport] = useState({
    results: [],
    grandTotal: 0,
    grandTotalBreakdown: {}
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewReceivablesPDF, setViewReceivablesPdf] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [includePaid, setIncludePaid] = useState(false);

  const theme = useTheme();

  const fetchReceivablesReport = async (params) => {
    setLoading(true);
    try {
      await ReportsStore.fetchSalesOutstandingReceivablesReport(params);
      setReceivablesReport(ReportsStore.detailedReports.outstandingReceivables);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to fetch sales receivables report', severity: 'error' });
    }
    setLoading(false);
  };

  const generateReceivablesReport = () => {
    const params = {
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : '',
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : '',
      includeAll: includePaid,
    };
    fetchReceivablesReport(params);
  };

  const handleClose = () => {
    setViewReceivablesPdf(false);
  };

  const columns = [
    { field: 'name', headerName: 'Customer Name', flex: 1 },
    { 
      field: 'total', 
      headerName: 'Total Balance', 
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value)
    },
    { 
      field: '90+', 
      headerName: '90+ Days', 
      flex: 1,
      valueFormatter: ({ value }) => formatCurrency(value)
    }
  ];

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
          <Typography variant="h4">Receivables Report</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 2, gap: 3 }}>
          <DateRangePicker
            label='Invoice Date Range'
            defaultValue='All Dates'
            onChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includePaid}
                onChange={(e) => setIncludePaid(e.target.checked)}
              />
            }
            label="Include Paid Receivables"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
          <Button variant="outlined" onClick={generateReceivablesReport} sx={{ width: '200px', fontWeight: 'bold' }}>
            Get Receivables
          </Button>
          {receivablesReport.results.length > 0 && (
            <Button onClick={() => setViewReceivablesPdf(true)} sx={{ width: '200px' }}>
              View as PDF
            </Button>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
          <DataGridPro
            rows={receivablesReport.results}
            columns={columns}
            autoHeight
            loading={loading}
            getRowId={(row) => row.name}
            getDetailPanelContent={(params) => <InvoiceDetailPanel row={params.row} />}
            getDetailPanelHeight={() => 200}
          />
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default ReceivablesReport;