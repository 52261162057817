import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import CommissionCodeStore from '../stores/CommissionCodeStore';
import DynamicSearchFilter from '../components/DynamicSearchFilter';
import {
  Box, Typography, IconButton, Snackbar, Button,
  TablePagination, useMediaQuery, List, ListItem, ListItemText,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const filterObject = {
  commissionCode: '',
  commissionDescription: '',
  comments: '',
};

const CommissionCodes = observer(() => {
  const [commissionCodes, setCommissionCodes] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCriteria, setFilterCriteria] = useState({});
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    fetchPaginatedCommissionCodes();
  }, []);

  const fetchPaginatedCommissionCodes = async (page = 1, pageSize = 10, sortBy = 'commissionCode', filter = filterCriteria, search = searchQuery) => {
    setLoading(true);
    CommissionCodeStore.fetchPaginatedCommissionCodes(page, pageSize, sortBy, filter, search).then(() => {
      setCommissionCodes(CommissionCodeStore.paginatedCommissionCodes);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch commission codes', severity: 'error' });
      setLoading(false);
    });
  };

  const handleViewCommissionCode = (commissionCodeId) => {
    console.log(commissionCodeId);
    navigate(`/commission-codes/${commissionCodeId}`);
  };

  const handleCreateCommissionCode = () => {
    navigate('/commission-codes/add');
  };

  const handleDeleteCommissionCode = async () => {
    try {
      await CommissionCodeStore.deleteCommissionCode(selectedRows[0]);
      setSnackbar({ open: true, message: 'Commission code deleted successfully', severity: 'success' });
      fetchPaginatedCommissionCodes();
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to delete commission code', severity: 'error' });
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, page) => {
    fetchPaginatedCommissionCodes(page + 1, commissionCodes.pageSize);
  };

  const handleLimitChange = (event) => {
    fetchPaginatedCommissionCodes(1, event.target.value);
  };

  const handleDynamicSearch = (search, filter, sortBy) => {
    setSearchQuery(search);
    setFilterCriteria(filter);

    let filterBuilder = {};
    if (search) {
      const searchFilter = {
        $or: [
          { commissionCode: { $regex: search, $options: 'i' } },
          { commissionDescription: { $regex: search, $options: 'i' } },
          { comments: { $regex: search, $options: 'i' } },
        ]
      }

      filterBuilder = { ...searchFilter };
    }

    fetchPaginatedCommissionCodes(1, commissionCodes.pageSize, sortBy, filterBuilder, search);
  };

  const handleBack = () => {
    navigate('/payables');
  }

  const handleEdit = () => {
    if (selectedRows.length === 1) {
      navigate(`/commission-codes/edit/${selectedRows[0]}`);
    }
  };

  const handleOpenDeleteDialog = () => {
    if (selectedRows.length === 1) {
      setDeleteDialogOpen(true);
    }
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button onClick={handleEdit} variant="outlined" disabled={selectedRows.length !== 1}>
        Edit
      </Button>
      <Button onClick={handleOpenDeleteDialog} startIcon={<DeleteIcon />} disabled={selectedRows.length !== 1}>
        Delete
      </Button>
    </GridToolbarContainer>
  );

  const columns = [
    { field: 'commissionCode', headerName: 'Commission Code', flex: 1, sortable: false },
    { field: 'commissionDescription', headerName: 'Description', flex: 1, sortable: false },
    { field: 'comments', headerName: 'Comments', flex: 1, sortable: false },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 2, mb: 2 }}>
        <DynamicSearchFilter
          defaultSort="commissionCode"
          onSearch={handleDynamicSearch}
          objectInterface={filterObject}
        />
        <IconButton color="primary" onClick={handleCreateCommissionCode}>
          <AddIcon />
        </IconButton>
      </Box>
      <Box>
        {
          commissionCodes.totalCount !== 0 ? (
            <>
              {isLargeScreen ? (
                <DataGridPro
                  rows={commissionCodes.results}
                  columns={columns}
                  pageSize={commissionCodes.pageSize}
                  rowCount={commissionCodes.totalCount}
                  loading={loading}
                  autoHeight
                  slots={{
                    toolbar: CustomToolbar
                  }}
                  checkboxSelection
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnSort
                  getRowId={(row) => row._id}
                  onRowSelectionModelChange={(selection) => {
                    setSelectedRows(selection);
                  }}
                  onRowClick={(params) => handleViewCommissionCode(params.id)}
                  editMode="row"
                  selectionModel={selectedRows}
                />
              ) : (
                <List>
                  {commissionCodes.results.map((commissionCode) => (
                    <ListItem
                      key={commissionCode._id}
                      button
                      onClick={() => handleViewCommissionCode(commissionCode._id)}
                    >
                      <ListItemText
                        primary={`Commission Code: ${commissionCode.commissionCode}`}
                        secondary={`Description: ${commissionCode.commissionDescription}, Comments: ${commissionCode.comments}`}
                      />
                    </ListItem>
                  ))}
                </List>

              )}
              <TablePagination
                component="div"
                count={commissionCodes.totalCount}
                page={commissionCodes.currentPage > 0 ? commissionCodes.currentPage - 1 : 0}
                onPageChange={handlePageChange}
                rowsPerPage={commissionCodes.pageSize}
                onRowsPerPageChange={handleLimitChange}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </>
          ) : (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography variant="subtitle1">No commission codes available</Typography>
            </Box>
          )
        }
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Commission Code</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this commission code?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteCommissionCode} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
});

export default CommissionCodes;