import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { format } from 'date-fns';

const OrderModal = observer(({ open, onClose, loading, shopOrders, setSelectedOrder, handleInputChange, handleAssignOrder }) => {
  const [sequenceOption, setSequenceOption] = useState('last');
  const [customSequence, setCustomSequence] = useState('');

  const handleAddOrder = () => {
    handleAssignOrder(sequenceOption, customSequence);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Select Order</DialogTitle>
      <DialogContent sx={{ height: '500px', maxHeight: '90vh' }}>
        <Box marginBottom={2}>
          <Autocomplete
            disablePortal
            fullWidth
            id="orders"
            getOptionLabel={(option) => `${option.salesOrderNumber} - ${option.customerName} - ${option.millJobNumber} - ${format(new Date(option.receivedDate), 'MM/dd/yyyy')}`}
            options={shopOrders?.results}
            onChange={(event, newValue) => setSelectedOrder(newValue)}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Open Orders"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <FormControl fullWidth margin="normal">
          <InputLabel id="sequence-option-label">Sequence Option</InputLabel>
          <Select
            labelId="sequence-option-label"
            value={sequenceOption}
            onChange={(event) => setSequenceOption(event.target.value)}
            label="Sequence Option"
          >
            <MenuItem value="first">First</MenuItem>
            <MenuItem value="last">Last</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </Select>
        </FormControl>
        {sequenceOption === 'custom' && (
          <TextField
            fullWidth
            margin="normal"
            label="Custom Sequence Number"
            value={customSequence}
            onChange={(event) => setCustomSequence(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleAddOrder} color="primary" disabled={loading}>
          Add Order
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default OrderModal;