import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Box, Typography, Button, TextField, Snackbar, Paper,
  Autocomplete, CircularProgress, debounce, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Toolbar, Checkbox, List, ListItem, ListItemSecondaryAction
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { ArrowBackIos, ArrowForwardIos, Delete as DeleteIcon } from '@mui/icons-material';
import MachineSearch from '../../components/search/MachineSearch';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import MachineStore from '../../stores/MachineStore';
import { toJS } from 'mobx';
import { format } from 'date-fns';
import CustomerStore from '../../stores/CustomerStore';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import OrderModal from '../../components/OrderModal/OrderModal';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MachineAssignment = observer(() => {
  const [machine, setMachine] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [sortBy, setSortBy] = useState('-enteredDate');
  const [assignedOrders, setAssignedOrders] = useState([]);
  const [shopOrders, setShopOrders] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [openOrderDialog, setOpenOrderDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [machines, setMachines] = useState([]);
  const [currentMachineIndex, setCurrentMachineIndex] = useState(0);
  const [editingSequence, setEditingSequence] = useState(null);
  const [ordersToUpdate, setOrdersToUpdate] = useState([]);
  const [sequenceOption, setSequenceOption] = useState('auto');
  const [customSequence, setCustomSequence] = useState(''); 


  const handleMachineChoice = async (machine) => {
    if (machine) {
      const index = machines.findIndex((m) => m._id === machine._id);
      setCurrentMachineIndex(index);
      setMachine(machine);
      await fetchAssignedOrders(machine);
    }
  };

  const handleAssignOrder = (sequenceOption, customSequence) => {
    if (selectedOrder) {
      let newSequence;
      if (sequenceOption === 'first') {
        newSequence = 1;
      } else if (sequenceOption === 'last') {
        newSequence = assignedOrders.length + 1;
      } else if (sequenceOption === 'custom') {
        newSequence = parseInt(customSequence, 10);
      } else {
        let maxSequence = assignedOrders.reduce((max, order) => (order.sequence > max ? order.sequence : max), 0);
        newSequence = maxSequence + 1;
      }
  
      selectedOrder.sequence = newSequence;
      selectedOrder.machine = machine._id;
  
      let updatedOrders = [...assignedOrders, toJS(selectedOrder)];
  
      if (sequenceOption !== 'last') {
        updatedOrders = updatedOrders
          .filter((order) => order._id !== selectedOrder._id)
          .sort((a, b) => a.sequence - b.sequence)
          .map((order, index) => {
            if (index + 1 >= newSequence) {
              return { ...order, sequence: index + 2 };
            }
            return { ...order, sequence: index + 1 };
          });
  
        updatedOrders.splice(newSequence - 1, 0, selectedOrder);
  
        updatedOrders = updatedOrders.map((order, index) => ({ ...order, sequence: index + 1 }));
      }
  
      setAssignedOrders(updatedOrders);
      console.log(updatedOrders.map((order) => ({ _id: order.shopOrderNumber, sequence: order.sequence })));
  
      let newShopOrders = shopOrders.results.filter((shopOrder) => shopOrder._id !== selectedOrder._id);
      setShopOrders({ ...shopOrders, results: newShopOrders });
      setOpenOrderDialog(false);
      setSelectedOrder(null);
    }
  };

  const reorderSequences = (orders) => {
    return orders
      .sort((a, b) => a.sequence - b.sequence)
      .map((order, index) => ({ ...order, sequence: index + 1 }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    setAssignedOrders((prev) => {
      const reorderedOrders = Array.from(prev);
      const [removed] = reorderedOrders.splice(result.source.index, 1);
      reorderedOrders.splice(result.destination.index, 0, removed);

      const updatedOrders = reorderedOrders.map((order, index) => ({
        ...order,
        sequence: index + 1,
      }));

      return updatedOrders;
    });
  };

  const handleSequenceBlur = (id) => {
    setEditingSequence(null);
    setAssignedOrders((prev) => {
      const updatedOrders = prev.map((order) => {
        if (order._id === id) {
          return { ...order, sequence: parseInt(order.sequence, 10) || 0 };
        }
        return order;
      });

      const orderToUpdate = updatedOrders.find((order) => order._id === id);
      if (!orderToUpdate) return prev;

      const newSequence = orderToUpdate.sequence;

      const reorderedOrders = updatedOrders
        .filter((order) => order._id !== id)
        .sort((a, b) => a.sequence - b.sequence)
        .map((order, index) => {
          if (index + 1 >= newSequence) {
            return { ...order, sequence: index + 2 };
          }
          return { ...order, sequence: index + 1 };
        });

      reorderedOrders.splice(newSequence - 1, 0, orderToUpdate);

      return reorderedOrders.map((order, index) => ({ ...order, sequence: index + 1 }));
    });
  };

  const handleInputChange = debounce(async (event, value, reason) => {
    if (value.length > 0 && reason === 'input') {
      const customerSearchFilter = {
        companyName: { $regex: value, $options: 'i' },
      };

      let filter = {
        finishedDate: null,
        machine: null,
        receivedDate: {
          $ne: null
        },
      };

      try {
        const customers = await CustomerStore.fetchPaginatedCustomers(1, 20, 'companyName', customerSearchFilter, '', 'companyName');
        if (customers && customers.results.length > 0) {
          filter = {
            ...filter,
            $or: [
              {
                customer: {
                  $in: customers.results.map((customer) => customer._id)
                }
              },
              { salesOrderNumber: { $regex: value, $options: 'i' } },
              { millJobNumber: { $regex: value, $options: 'i' } }
            ]
          };
        } else {
          filter = {
            ...filter,
            $or: [
              { salesOrderNumber: { $regex: value, $options: 'i' } },
              { millJobNumber: { $regex: value, $options: 'i' } }
            ]
          };
        }
      } catch (error) {
        filter = {
          ...filter,
          $or: [
            { salesOrderNumber: { $regex: value, $options: 'i' } },
            { millJobNumber: { $regex: value, $options: 'i' } }
          ]
        };
      }
      fetchPaginatedShopOrders(1, 100, sortBy, filter);
    }
  }, 500);

  const fetchPaginatedShopOrders = async (page = 1, pageSize = 10, sortBy = '-enteredDate', filter = {}, search = '', searchField = 'salesOrderNumber') => {
    setLoading(true);
    ShopOrdersStore.fetchPaginatedShopOrders(page, pageSize, sortBy, filter, search, searchField).then(() => {
      setShopOrders(ShopOrdersStore.paginatedShopOrders);
      setLoading(false);
    }).catch(error => {
      if (error.response?.data?.message && error.response.data.message === 'No documents found.') {
        setShopOrders({ hasMore: false, totalCount: 0, currentPage: 0, pageSize: 10, totalPages: 0, results: [] });
        setLoading(false);
        setSnackbar({ open: true, message: 'No orders found', severity: 'info' });
        return;
      }
      setSnackbar({ open: true, message: 'Failed to fetch shop orders', severity: 'error' });
      setLoading(false);
    });
  };

  const fetchAssignedOrders = async (machine) => {
    const filter = {
      machine: machine._id,
      finishedDate: null
    };
    setLoading(true);
    ShopOrdersStore.fetchPaginatedShopOrders(1, 20, 'sequence', filter, '').then(() => {
      setAssignedOrders(toJS(ShopOrdersStore.paginatedShopOrders.results));
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch assigned orders', severity: 'error' });
      setLoading(false);
    });
  };

  const fetchMachines = async () => {
    try {
      await MachineStore.fetchPaginatedMachines(1, 20, 'name', {}, '', 'name');
      setMachines(MachineStore.paginatedMachines.results);
      if (MachineStore.paginatedMachines.results.length > 0) {
        setMachine(MachineStore.paginatedMachines.results[0]);
        fetchAssignedOrders(MachineStore.paginatedMachines.results[0]);
      }
    } catch (error) {
      console.error('Failed to fetch machines:', error);
    }
  };

  const handleSave = async () => {
    const allOrdersToUpdate = [...assignedOrders, ...ordersToUpdate];
    allOrdersToUpdate.forEach(async (order) => {
      try {
        await ShopOrdersStore.updateShopOrder(order._id, order);
      } catch (error) {
        setSnackbar({ open: true, message: `Failed to save assignment: ${order.salesOrderNumber}`, severity: 'error' });
        return;
      }
    });
  
    setSnackbar({ open: true, message: 'Machine assignments saved', severity: 'success' });
    setOrdersToUpdate([]); // Clear the orders to update after saving
  };

  const handleDeleteOrder = () => {
    setAssignedOrders((prev) => {
      const updatedOrders = prev.map((order) => {
        if (selectedOrders.includes(order._id)) {
          const updatedOrder = { ...order, sequence: null, machine: null };
          setOrdersToUpdate((prevOrders) => [...prevOrders, updatedOrder]);
          return updatedOrder;
        }
        return order;
      });
  
      const filteredOrders = updatedOrders.filter(order => !selectedOrders.includes(order._id));
      return reorderSequences(filteredOrders);
    });
    setSelectedOrders([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedOrders.indexOf(id);
    let newSelected = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedOrders, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedOrders.slice(1));
    } else if (selectedIndex === selectedOrders.length - 1) {
      newSelected = newSelected.concat(selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedOrders.slice(0, selectedIndex),
        selectedOrders.slice(selectedIndex + 1)
      );
    }
  
    setSelectedOrders(newSelected);
  };

  const isSelected = (id) => selectedOrders.indexOf(id) !== -1;

  const handleSequenceChange = (id, newValue) => {
    setAssignedOrders((prev) => {
      return prev.map((order) => {
        if (order._id === id) {
          return { ...order, sequence: newValue };
        }
        return order;
      });
    });
  };



  const handlePreviousMachine = () => {
    const newIndex = (currentMachineIndex - 1 + machines.length) % machines.length;
    setCurrentMachineIndex(newIndex);
    setMachine(machines[newIndex]);
    fetchAssignedOrders(machines[newIndex]);
  };

  const handleNextMachine = () => {
    const newIndex = (currentMachineIndex + 1) % machines.length;
    setCurrentMachineIndex(newIndex);
    setMachine(machines[newIndex]);
    fetchAssignedOrders(machines[newIndex]);
  };

  useEffect(() => {
    fetchMachines();
  }, []);

  return (
    <Box>
      <MachineSearch label="Select Machine" handleChange={handleMachineChoice} />

      {machine && (
        <Box marginTop={3}>
          <Button variant="outlined" color="primary" onClick={() => setOpenOrderDialog(true)} disabled={loading}>
            Add Order
          </Button>
          <OrderModal 
            open={openOrderDialog} 
            onClose={() => setOpenOrderDialog(false)} 
            loading={loading} 
            shopOrders={shopOrders} 
            setSelectedOrder={setSelectedOrder} 
            handleInputChange={handleInputChange} 
            handleAssignOrder={handleAssignOrder} 
            sequenceOption={sequenceOption} 
            setSequenceOption={setSequenceOption} 
            customSequence={customSequence} 
            setCustomSequence={setCustomSequence}
          />
          <Box display="flex" alignItems="center" justifyContent="center" marginTop={2}>
            <IconButton onClick={handlePreviousMachine} disabled={loading}>
              <ArrowBackIos />
            </IconButton>
            <Typography variant="h5" component="h2" gutterBottom align='center' margin={2}>
              Machine Assignments for {machine.name} / {machine.operators.join(', ')}
            </Typography>
            <IconButton onClick={handleNextMachine} disabled={loading}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
          <Box>
            <Toolbar>
              <Button
                onClick={handleDeleteOrder}
                disabled={loading || selectedOrders.length === 0}
                color="secondary"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Toolbar>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="assignedOrders">
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {loading ? (
                      <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                        <CircularProgress />
                      </Box>
                    ) : (
                      assignedOrders.map((order, index) => (
                        <Draggable key={order._id} draggableId={order._id} index={index}>
                          {(provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              divider
                              sx={{ display: 'flex', alignItems: 'center', flexWrap: { xs: 'wrap', lg: 'nowrap' } }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%', lg: 'auto' } }}>
                                <Checkbox
                                  checked={isSelected(order._id)}
                                  onClick={(event) => handleClick(event, order._id)}
                                  disabled={loading}
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                                  {editingSequence === order._id ? (
                                    <TextField
                                      value={order.sequence}
                                      onChange={(event) => handleSequenceChange(order._id, event.target.value)}
                                      onBlur={() => handleSequenceBlur(order._id)}
                                      onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                          handleSequenceBlur(order._id);
                                        }
                                      }}
                                      variant="outlined"
                                      size="small"
                                      autoFocus
                                      disabled={loading}
                                    />
                                  ) : (
                                    <Typography
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() => setEditingSequence(order._id)}
                                    >
                                      {order.sequence}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, flexGrow: 1, alignItems: 'start', marginLeft: 2 }}>
                                <Box sx={{ marginRight: { lg: 2 }, marginBottom: { xs: 1, lg: 0 }, flexGrow: 1, minWidth: '200px' }}>
                                  <Typography noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{order.customerName}</Typography>
                                  <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: { xs: 'start', lg: 'center' } }}>
                                    <Typography variant="caption">Order Number:</Typography>
                                    &nbsp; &nbsp;
                                    <Typography>{order.salesOrderNumber}</Typography>
                                  </Box>
                                </Box>
                                <Box sx={{ marginRight: { lg: 2 }, marginBottom: { xs: 1, lg: 0 } }}>
                                  <Typography variant="caption">Mill Job Number</Typography>
                                  <Typography>{order.millJobNumber}</Typography>
                                </Box>
                                <Box sx={{ marginRight: { lg: 2 }, marginBottom: { xs: 1, lg: 0 } }}>
                                  <Typography variant="caption">Tube Outer Diameter</Typography>
                                  <Typography>{order.tubeOuterDiameter}</Typography>
                                </Box>
                                <Box sx={{ marginRight: { lg: 2 }, marginBottom: { xs: 1, lg: 0 } }}>
                                  <Typography variant="caption">Hone Inner Diameter</Typography>
                                  <Typography>{order.honeInnerDiameter?.upperLimit || 0}</Typography>
                                </Box>
                                <Box sx={{ marginRight: { lg: 2 }, marginBottom: { xs: 1, lg: 0 } }}>
                                  <Typography variant="caption">Total Hours</Typography>
                                  <Typography>{order.totalHours}</Typography>
                                </Box>
                                <Box sx={{ marginRight: { lg: 2 }, marginBottom: { xs: 1, lg: 0 } }}>
                                  <Typography variant="caption">Total Pieces</Typography>
                                  <Typography>{order.totalPieces?.actual || 0}</Typography>
                                </Box>
                                <Box sx={{ marginRight: { lg: 2 }, marginBottom: { xs: 1, lg: 0 } }}>
                                  <Typography variant="caption">Total Feet</Typography>
                                  <Typography>{order.totalFeet?.actual || 0}</Typography>
                                </Box>
                              </Box>

                            </ListItem>
                          )}
                        </Draggable>
                      ))
                    )}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box margin={2}>
            <Button onClick={handleSave} disabled={loading}>Save</Button>
          </Box>
        </Box>
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default MachineAssignment;