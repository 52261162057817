import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Typography, Paper, Box, Button, Snackbar, TextField, Grid, Modal, IconButton, useTheme
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ReportsStore from '../../../stores/ReportsStore';
import dayjs from 'dayjs';
import { PDFViewer } from '@react-pdf/renderer';
import CloseIcon from '@mui/icons-material/Close';
import EmployeeSearch from '../../../components/search/EmployeeSearch';
import PayrollPDF from '../../../pdf/EmployeePayrollPDF';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PayrollReport = observer(() => {
  const [report, setReport] = useState({
    result: [],
    totals: {},
    message: '',
    status: ''
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [workDateFrom, setWorkDateFrom] = useState(null);
  const [workDateTo, setWorkDateTo] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    const today = new Date();
    const sunday = new Date(today);
    sunday.setDate(today.getDate() - today.getDay());
    const saturday = new Date(sunday);
    saturday.setDate(sunday.getDate() + 6);

    setWorkDateFrom(dayjs(sunday));
    setWorkDateTo(dayjs(saturday));
  }, []);

  const fetchEmployeePayroll = async (params) => {
    setLoading(true);
    ReportsStore.fetchEmployeePayrollReport(params).then(() => {
      setReport(ReportsStore.detailedReports.employeePayroll);
      setLoading(false);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Failed to fetch employee payroll report', severity: 'error' });
      setLoading(false);
    });
  };

  const generateReports = () => {
    const workStart = workDateFrom ? dayjs(workDateFrom).startOf('day').format('YYYY-MM-DD') : '';
    const workEnd = workDateTo ? dayjs(workDateTo).endOf('day').format('YYYY-MM-DD') : '';
    const payrollReportParams = {
      employeeId: employeeId,
      startDate: workStart,
      endDate: workEnd
    };
    fetchEmployeePayroll(payrollReportParams);
  };

  const handleClose = () => {
    setViewPdf(false);
  };

  const columns = [
    { field: 'name', headerName: 'Employee Name', flex: 2 },
    { field: 'employeeId', headerName: 'Employee ID', flex: 1 },
    { field: 'negIncentives', headerName: '-(Inc)', flex: 1, valueFormatter: ({ value }) => value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' },
    { field: 'regularHours', headerName: 'Reg Hrs', flex: 1 },
    { field: 'overtimeHours', headerName: 'OT Hours', flex: 1 },
    { field: 'vacation', headerName: 'Vacation', flex: 1, valueFormatter: ({ value }) => value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' },
    { field: 'holiday', headerName: 'Holiday', flex: 1, valueFormatter: ({ value }) => value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' },
    { field: 'attendance', headerName: 'Travel Incentive', flex: 1, valueFormatter: ({ value }) => value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' },
    { field: 'otherIncome', headerName: 'Other', flex: 1, valueFormatter: ({ value }) => value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' },
    { field: 'incentives', headerName: 'Incentive', flex: 1, valueFormatter: ({ value }) => value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' },
    { field: 'quality', headerName: 'Quality', flex: 1, valueFormatter: ({ value }) => value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' },
    { field: 'bonus', headerName: 'Bonus', flex: 1, valueFormatter: ({ value }) => value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' },
    { field: 'totalPay', headerName: 'Total Pay', flex: 1, valueFormatter: ({ value }) => value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' }
  ];

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
          <Typography variant="h4">Payroll Report</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={3} padding={2}>
            {/* Employee Name Dropdown */}
            <Grid item xs={12} md={6}>

              <EmployeeSearch
                sx={{ width: '100%' }}
                handleChange={(event) => setEmployeeId(event ? event._id : '')}
                showAdd={false}
                marginTop={0}
              />

            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DatePicker
                  label="Work Days From"
                  value={workDateFrom}
                  onChange={(newValue) => setWorkDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  label="Work Days To"
                  value={workDateTo}
                  onChange={(newValue) => setWorkDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
              <Button variant="outlined" onClick={() => generateReports()} sx={{ width: '200px', fontWeight: 'bold' }} disabled={loading}>Get Payroll</Button>
              {report.result?.length > 0 && (
                <Button
                  onClick={() => setViewPdf(true)} sx={{ width: '200px' }} disabled={loading}>View as PDF</Button>
              )}
            </Box>
          </Grid>
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 2 }}>
        {viewPdf ? (
          <Modal
            open={viewPdf}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >

            <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton
                onClick={handleClose}
                sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
              >
                <CloseIcon />
              </IconButton>
              <PDFViewer width={'100%'} height={'100%'}>
                <PayrollPDF
                  data={report.result}
                  startDate={workDateFrom}
                  endDate={workDateTo}
                />
              </PDFViewer>
            </Paper>
          </Modal>
        ) : (
          <DataGridPro
            rows={report?.result || []}
            columns={columns}
            autoHeight
            loading={loading}
            getRowId={(row) => row.name}
          />
        )}

      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity="info" sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default PayrollReport;
