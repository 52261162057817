import React, { useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import OrdersPage from './order/Orders';
import ProductionPage from './order/Production';
import MachineAssignmentPage from './order/MachineAssignment';
import MachineOperatorsPage from './order/MachineOperators';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';

const OrderTabs = observer(() => {
  const [tab, setTab] = useState('orders');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');

    if (tabParam) {
      setTab(tabParam);
    } else {
      const previousTab = localStorage.getItem('orderTab');
      if (previousTab) {
        setTab(previousTab);
      }
    }
  }, [location.search]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('orderTab', newValue);
    navigate(`?tab=${newValue}`);
  };

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleTabChange} aria-label="Order Tabs">
          <Tab label="Orders" value="orders" />
          <Tab label="Production" value="production" />
          <Tab label="Machine Assignment" value="machineAssignment" />
          <Tab label="Machine Operators" value="machineOperators" />
        </TabList>
      </Box>
      <TabPanel value="orders">
        <OrdersPage />
      </TabPanel>
      <TabPanel value="production">
        <ProductionPage />
      </TabPanel>
      <TabPanel value="machineAssignment">
        <MachineAssignmentPage />
      </TabPanel>
      <TabPanel value="machineOperators">
        <MachineOperatorsPage />
      </TabPanel>
    </TabContext>
  );
});

export default OrderTabs;