import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import PayablesStore from '../stores/PayablesStore';
import {
  Box, Typography, CircularProgress, Snackbar, IconButton,
  Paper, Tooltip, Button, TextField, Chip, Modal, TablePagination,
  Alert, ToggleButton, ToggleButtonGroup, Fab,
  Menu, MenuItem, List, ListItem, ListItemText, ListItemAvatar, Badge, Avatar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EmptyStateIcon from '@mui/icons-material/Square';
import MoneyIcon from '@mui/icons-material/Money';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DataGridPro, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { format, isValid } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import CheckPDF from '../pdf/CheckPDF';
import { formatCurrency, formatDate, formatNumber } from '../utils/numberFormatting';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import VendorSearch from '../components/search/VendorSearch';
import MoreVertIcon from '@mui/icons-material/MoreVert'

const CustomFooterComponent = ({ outstandingAmount, paymentTotal, handleSave }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2, gap: 2 }}>
    <Typography fontWeight={'bold'}>Total Outstanding: {formatCurrency(outstandingAmount)}</Typography>
    <Typography fontWeight={'bold'}>Total To Be Paid: {formatCurrency(paymentTotal || 0)}</Typography>
    <Button variant="outlined" color="primary" onClick={handleSave}>Save</Button>
  </Box>
);

const CustomToolbar = ({ handleFilterChange, selectedInvoices, totalSelectedAmount, handleEdit, handleDelete, handlePrintPayable, handlePay }) => (
  <GridToolbarContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
    {selectedInvoices.length === 1 && (
      <Button variant="outlined" onClick={handleEdit} sx={{ marginRight: 2 }}>
        Edit
      </Button>
    )}
    {selectedInvoices.length > 0 && (
      <>
        <Box sx={{ display: 'flex' }}>
          <Button variant="outlined" color="error" onClick={handleDelete} sx={{ marginRight: 2 }}>
            Delete
          </Button>
          <Button variant="outlined" onClick={handlePrintPayable} sx={{ marginRight: 2 }}>
            Print
          </Button>
          <Button variant="outlined" color="primary" onClick={handlePay} sx={{ marginRight: 2 }}>
            Pay
          </Button>
        </Box>
        <Typography variant="h6" sx={{ marginLeft: 'auto' }}>
          Total: {formatCurrency(totalSelectedAmount)}
        </Typography>
      </>
    )}
  </GridToolbarContainer>
);

const Payables = observer(() => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [loading, setLoading] = useState(false);
  const [payables, setPayables] = useState({
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: []
  });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [totalSelectedAmount, setTotalSelectedAmount] = useState(0);
  const [viewPdf, setViewPdf] = useState(false);
  const [checkData, setCheckData] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [dueDateFrom, setDueDateFrom] = useState(null);
  const [dueDateTo, setDueDateTo] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [filterType, setFilterType] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [sortModel, setSortModel] = useState([{ field: 'datePaid', sort: 'desc' }]);


  useEffect(() => {
    fetchPaginatedPayables();
  }, [page, pageSize, vendor, invoiceNumber, dueDateFrom, dueDateTo, filterType]);

  const fetchPaginatedPayables = (page = 1, pageSize = 10, sortField = 'datePaid', sortDirection = 'desc') => {
    setLoading(true);

    const filter = {};
    if (invoiceNumber) filter.invoiceNo = { $regex: invoiceNumber, $options: 'i' };
    if (dueDateFrom || dueDateTo) {
      filter.dueDate = {};
      if (dueDateFrom) filter.dueDate.$gte = dueDateFrom;
      if (dueDateTo) filter.dueDate.$lte = dueDateTo;
    }
    if (vendor) filter.vendor = vendor;

    if (filterType === 'unpaid') {
      filter.paidAmount = { $eq: 0 };
      filter.invoiceAmount = { $ne: 0 };
    } else if (filterType === 'paid') {
      filter.paidAmount = { $gt: 0 };
    }

    PayablesStore.fetchPaginatedPayables(page, pageSize, `${sortDirection === 'desc' ? '-' : ''}${sortField}`, filter)
      .then(() => {
        setPayables(PayablesStore.paginatedPayables);
        setLoading(false);
      })
      .catch(() => {
        setSnackbar({ open: true, message: 'Failed to fetch payables', severity: 'error' });
        setLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    const sortField = sortModel[0]?.field || 'datePaid';
    const sortDirection = sortModel[0]?.sort || 'desc';
    fetchPaginatedPayables(0, newPageSize, sortField, sortDirection);
  };

  const handleSelectionChange = (selectionModel) => {
    setSelectedInvoices(selectionModel);
    const selectedPayables = payables.results.filter(payable => selectionModel.includes(payable._id));
    const totalAmount = selectedPayables.reduce((sum, payable) => sum + payable.invoiceAmount, 0);
    setTotalSelectedAmount(totalAmount);
  };

  const handleCreatePayable = () => {
    navigate('/payable/add');
  };

  const handleEditPayable = () => {
    if (selectedInvoices.length === 1) {
      navigate(`/payable/edit/${selectedInvoices[0]}`);
    }
  };

  const handleDeletePayable = () => {
    // Implement logic to delete selected invoices
  };

  const handlePay = () => {
    // Implement logic to mark the selected invoices as paid
  };

  const handlePrintPayable = () => {
    const selectedPayables = payables.results.filter((payable) => selectedInvoices.includes(payable._id));
    const combinedPayables = selectedPayables.reduce((acc, curr) => {
      acc.amount += curr.invoiceAmount;
      acc.text += `Invoice No: ${curr.invoiceNo}, Amount: $${curr.invoiceAmount.toFixed(2)}\n`;
      return acc;
    }, { amount: 0, text: '' });

    const today = new Date();
    const formattedDate = Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    }).format(today);

    const data = {
      checkNumber: 'Combined Check',
      date: formattedDate,
      payee: selectedPayables[0]?.vendor.vendorName,
      amount: combinedPayables.amount,
      text: combinedPayables.text
    };
    setCheckData(data);
    setViewPdf(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleFilterChange = (event, newFilterType) => {
    if (newFilterType !== null) {
      setFilterType(newFilterType);
    }
  };

  const handleRowClick = (params, event) => {
    if (event && event.target.closest('.MuiDataGrid-cellCheckbox')) return;
    navigate(`/payable/${params.id}`);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const handleAccountCodesClick = () => {
    navigate('/account-codes');
    handleMenuClose();
  }

  const columns = [
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      flex: 1,
      sortable: true,
      valueGetter: (params) => params.row.vendor?.vendorName
    },
    {
      field: 'invoiceNo',
      sortable: true,
      headerName: 'Invoice No',
      flex: 1
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      sortable: true,
      flex: 1,
      valueFormatter: (params) => params.value ? format(new Date(params.value), 'MM/dd/yyyy') : 'N/A'
    },
    {
      field: 'invoiceAmount',
      headerName: 'Invoice Amount',
      sortable: true,
      flex: 1,
      valueGetter: (params) => `$${params.row.invoiceAmount.toFixed(2)}`
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Chip
          label={
            params.row.paidAmount >= params.row.invoiceAmount
              ? 'Paid'
              : params.row.paidAmount > 0
                ? 'Partially Paid'
                : 'Unpaid'
          }
          color={
            params.row.paidAmount >= params.row.invoiceAmount
              ? 'success'
              : params.row.paidAmount > 0
                ? 'warning'
                : 'error'
          }
        />

      )
    },
    {
      field: 'datePaid',
      sortable: true,
      headerName: 'Paid Date',
      flex: 1,
      valueFormatter: (params) => params.value ? format(new Date(params.value), 'MM/dd/yyyy') : 'N/A'
    },
    {
      field: 'checkNo',
      sortable: true,
      headerName: 'Check No',
      flex: 1,
    }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2, gap: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" gutterBottom>Payables</Typography>
          <Box>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleCreatePayable}>Add Payable</MenuItem>
              <MenuItem onClick={handleAccountCodesClick}>Account Codes</MenuItem>
            </Menu>
          </Box>
        </Box>
        <VendorSearch label="Search Vendors" handleChange={(vendor) => setVendor(vendor?._id || null)} />
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Invoice #"
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
          />
          <DatePicker
            label="Due Date From"
            value={dueDateFrom}
            onChange={(date) => setDueDateFrom(date)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="Due Date To"
            value={dueDateTo}
            onChange={(date) => setDueDateTo(date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, }}>
          <ToggleButtonGroup
            value={filterType}
            exclusive
            onChange={handleFilterChange}
            aria-label="invoice filter"
          >
            <ToggleButton value="all" aria-label="All Invoices">
              All
            </ToggleButton>
            <ToggleButton value="unpaid" aria-label="Unpaid">
              Unpaid
            </ToggleButton>
            <ToggleButton value="paid" aria-label="Paid">
              Paid
            </ToggleButton>
          </ToggleButtonGroup>
          <Tooltip title="New Payable">
            <Box>
              <IconButton color="primary" onClick={handleCreatePayable} >
                <AddIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
        {viewPdf && (
          <Modal
            open={viewPdf}
            onClose={() => setViewPdf(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}
          >
            <Box sx={{ width: '90%', height: '90%', padding: 1 }}>
              <IconButton onClick={() => setViewPdf(false)} sx={{ position: 'absolute', right: 16, top: 16, color: 'white' }}>
                <CloseIcon />
              </IconButton>
              <PDFViewer width="100%" height="100%">
                <CheckPDF report={checkData} />
              </PDFViewer>
            </Box>
          </Modal>
        )}
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {isLargeScreen ? (
              <DataGridPro
                rows={payables.results}
                columns={columns}
                pageSize={pageSize}
                rowCount={payables.totalCount}
                loading={loading}
                checkboxSelection
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  const sortField = newSortModel[0]?.field || 'datePaid';
                  const sortDirection = newSortModel[0]?.sort || 'desc';
                  setSortModel(newSortModel);
                  fetchPaginatedPayables(page, pageSize, sortField, sortDirection);
                }}
                onPageChange={handlePageChange}
                onPageSizeChange={handleRowsPerPageChange}
                paginationMode="server"
                getRowClassName={(params) => 'clickable'}
                onRowClick={handleRowClick}
                onRowSelectionModelChange={(selection) => handleSelectionChange(selection)}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar
                }}
                slotProps={{
                  toolbar: {
                    handleFilterChange,
                    selectedInvoices,
                    totalSelectedAmount,
                    handleEdit: handleEditPayable,
                    handleDelete: handleDeletePayable,
                    handlePrintPayable,
                    handlePay
                  }
                }}
              />
            ) : (
              <List>
                {payables.results.length === 0 ? (
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <EmptyStateIcon color="action" fontSize="large" />
                    <Typography variant="h6" color="textSecondary">
                      No payables available.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Once invoices are created, they will appear here.
                    </Typography>
                  </Box>
                ) : (
                  payables.results.map((payable) => (
                    <ListItem
                      key={payable?._id}
                      button
                      onClick={() => handleRowClick({ id: payable?._id } || '')}
                      sx={{
                        borderRadius: "8px",
                        transition: "background-color 0.3s",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)"
                        }
                      }}
                    >
                      <ListItemAvatar>
                        <Badge
                          badgeContent={payable?.paidAmount >= payable?.invoiceAmount ? "Paid" : "Due"}
                          color={payable?.paidAmount >= payable?.invoiceAmount ? "success" : "error"}
                          overlap="circular"
                        >
                          <Avatar>
                            <MoneyIcon />
                          </Avatar>
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" fontWeight="bold">
                            {payable?.vendor?.vendorName || "Vendor not available"}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="body2" color="textSecondary">
                              Invoice No: {payable?.invoiceNo || "N/A"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Due Date: {payable?.dueDate ? formatDate(payable.dueDate) : "N/A"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Total Amount: {payable?.invoiceAmount ? formatCurrency(payable.invoiceAmount) : "N/A"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Unpaid Amount: {payable?.invoiceAmount ? formatCurrency(Math.max(0, payable.invoiceAmount - (payable.paidAmount || 0))) : "N/A"}
                            </Typography>
                          </>
                        }
                      />
                      <Chip
                        label={
                          payable?.paidAmount >= payable?.invoiceAmount
                            ? "Paid"
                            : payable?.paidAmount > 0
                              ? "Partially Paid"
                              : "Unpaid"
                        }
                        color={
                          payable?.paidAmount >= payable?.invoiceAmount
                            ? "success"
                            : payable?.paidAmount > 0
                              ? "warning"
                              : "error"
                        }
                        variant="outlined"
                      />
                      <IconButton edge="end" aria-label="edit">
                        <ArrowForwardIosIcon />
                      </IconButton>
                    </ListItem>
                  ))
                )}
              </List>

            )}
            <TablePagination
              component="div"
              count={payables.totalCount}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </>
        )}

        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </LocalizationProvider>
    </Box>
  );
});

export default Payables;
