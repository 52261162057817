import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CommissionCodeStore from '../../stores/CommissionCodeStore';
import CommissionCodeForm from './CommissionCodeForm';
import { Box, Typography, CircularProgress, Snackbar, Alert, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

const EditCommissionCode = () => {
  const { commissionCodeId } = useParams();
  const navigate = useNavigate();
  const [commissionCode, setCommissionCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

  useEffect(() => {
    setLoading(true);
    CommissionCodeStore.fetchCommissionCodeById(commissionCodeId).then(() => {
      setCommissionCode(CommissionCodeStore.commissionCode);
      setLoading(false);
    }).catch((error) => {
      setSnackbar({ open: true, message: 'Failed to fetch commission code', severity: 'error' });
      setLoading(false);
    });
  }, [commissionCodeId]);

  const handleSubmit = async () => {
    try {
      await CommissionCodeStore.updateCommissionCode(commissionCodeId, commissionCode);
      navigate('/commission-codes');
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to update commission code', severity: 'error' });
    }
  };

  const handleChange = (updatedCommissionCode) => {
    setCommissionCode(updatedCommissionCode);
  };

  const handleBack = () => {
    navigate('/commission-codes');
  };

  const handleView = () => {
    navigate(`/commission-codes/${commissionCodeId}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" sx={{ ml: 2 }}>Edit Commission Code</Typography>
        </Box>

        <IconButton onClick={handleView}>
          <CloseIcon />
        </IconButton>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        commissionCode && (
          <CommissionCodeForm commissionCode={commissionCode} onSubmit={handleSubmit} back={handleBack} onChange={handleChange} />
        )
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditCommissionCode;