import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommissionCodeStore from '../../stores/CommissionCodeStore';
import CommissionCodeForm from './CommissionCodeForm';
import { Box, Typography, CircularProgress, Snackbar, Alert } from '@mui/material';

const AddCommissionCode = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
  const [commissionCode, setCommissionCode] = useState({
    commissionCode: '',
    commissionDescription: '',
    isDeleted: false,
    comments: ''
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await CommissionCodeStore.createCommissionCode(commissionCode);
      navigate('/commission-codes');
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to create commission code', severity: 'error' });
      setLoading(false);
    }
  };

  const handleChange = (updatedCommissionCode) => {
    setCommissionCode(updatedCommissionCode);
  };

  const handleBack = () => {
    navigate('/commission-codes');
  };

  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h4">Add New Commission Code</Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <CommissionCodeForm commissionCode={commissionCode} onSubmit={handleSubmit} back={handleBack} onChange={handleChange} />
      )}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddCommissionCode;